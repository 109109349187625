import * as Sentry from '@sentry/nuxt';

if (useRuntimeConfig().public.sentry.dsn) {
    Sentry.init({
        // If set up, you can use your runtime config here
        dsn: useRuntimeConfig().public.sentry.dsn,
        environment: useRuntimeConfig().public.sentry.environment,

        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: 1.0,

        // This sets the sample rate to be 10%. You may want this to be 100% while
        // in development and sample at a lower rate in production
        replaysSessionSampleRate: 0.1,

        // If the entire session is not sampled, use the below sample rate to sample
        // sessions when an error occurs.
        replaysOnErrorSampleRate: 1.0,

        // Make sure attached test data is nested deeper
        normalizeDepth: 8,

        // Attach Pinia State to Sentry
        integrations: [
            Sentry.replayIntegration({
                unmask: ['[data-sentry-unmask]'],
            }),
            Sentry.piniaIntegration(usePinia(), {
                stateTransformer: (state) => {
                    const transformedState = { ...state };
                    if (transformedState.hotels) {
                        transformedState.hotels = undefined;
                        transformedState.roomOffers = undefined;
                    }
                    return transformedState;
                },
            }),
        ],
    });
}
